import React from 'react';
import { Link } from 'gatsby';
import ContentPageWrapper from '../containers/Charity/ChapterGeneral/contentPageWrapper';
import imagePredhovor from '../common/src/assets/image/charity/specter/kapitola_predhovor_small.jpg';
import SEO from '../components/seo';

export default () => {
  return (
    <ContentPageWrapper>
      <SEO title="Predhovor | Ako duch komunizmu vládne nášmu svetu"
           description="Predhovor: Duch komunizmu nezmizol s rozpadom komunistickej strany vo východnej Európe"
           image={imagePredhovor}/>
      <h1>Predhovor</h1>
      <p>Hoci sa komunistické režimy východnej Európy rozpadli, zlý duch&nbsp; komunizmu nezmizol. Naopak, tento zlý
        duch už vládne nášmu svetu a ľudstvo by si o ňom nemalo prechovávať žiadne falošné nádeje.</p>
      <p>Komunizmus nie je ani myšlienkový smer, ani doktrína, a dokonca ani neúspešný pokus o nový spôsob spravovania
        ľudských záležitostí. Namiesto toho by sme ho mali chápať ako diabla – ako zlého ducha, ktorý je tvorený
        nenávisťou, zvrhlosťou a ďalšími živelnými silami vo vesmíre.</p>
      <p>V inej dimenzii, ktorá nie je pre nás viditeľná, prevzal na seba podobu hada, potom červeného draka a sprevádza
        satana, ktorý nenávidí Boha. Využíva bytosti a démonov na nízkej úrovni, aby spôsobil ľudstvu zmätok. Cieľom
        tohto ducha je zničiť ľudstvo. Zatiaľ čo nebesá ponúkajú ľuďom spásu, komunizmus ľuďom hovorí, aby v nich
        neverili. Útočí na ľudskú morálku, aby ľudstvo zavrhlo tradície. Jeho cieľom je, aby ľudstvo nevenovalo
        pozornosť božím pokynom a nakoniec bolo zničené.</p>
      <p>Zlý duch komunizmu sa prejavuje v nespočetných obmenách a je veľmi ľstivý. Niekedy používa vraždenie a násilie
        na zničenie tých, ktorí ho odmietajú nasledovať. Inokedy sa uchýli k použitiu jazyka „vedy” a „pokroku”, aby
        predostrel úžasný plán budúcnosti a oklamal tak ľudí. Niekedy sa tvári ako hlboký vedný odbor, vďaka ktorému
        ľudia veria, že ide o budúce smerovanie ľudstva. Inokedy zase použije slogany „demokracie”, „rovnosti” a
        „sociálnej spravodlivosti”, aby sa infiltroval do oblasti vzdelávania, médií, umenia a práva a zjednotil ľudí
        pod svojou vlajkou bez toho, aby si toho boli vedomí. Ešte inokedy však prevezme ľavicové názvy ako
        „socializmus”, „progresivizmus”, „liberalizmus”, „neomarxizmus” a podobne.</p>
      <p>Niekedy pozdvihne zdanlivo spravodlivé vlajky, ako napríklad pacifizmus, enviromentalizmus, globalizácia a
        politická korektnosť. V iných prípadoch podporí avantgardné umenie, sexuálnu slobodu, legalizáciu drog,
        homosexualitu a ďalšie oddávanie sa ľudským túžbam, aby v ľuďoch vyvolal dojem, že ide o súčasť populárneho
        trendu.</p>
      <p>Extrémizmus a násilie nie sú jeho jedinými prejavmi – niekedy predstiera, že mu ide o blaho spoločnosti. Jeho
        základným cieľom je však akýmkoľvek zničiť spôsobom všetko, čo je tradičné, či už ide o vieru, náboženstvo,
        morálku, kultúru, inštitúciu rodiny, umenie, pedagogiku, právo – urobí čokoľvek, len aby spôsobil, že ľudstvo
        upadne do morálnej priepasti a bude zatratené.</p>
      <p>Komunizmus a jeho rôzne mutácie sa dnes vyskytujú po celom svete. Kým Čína alebo Kuba otvorene vyhlasujú, že sú
        riadené komunistickým režimom, dokonca aj Spojené štáty – ktoré stoja na čele slobodného sveta – sa stali obeťou
        útokov tohto zlého ducha, nehovoriac o Európe, ktorá je socialistická, a Afrike a Latinskej Amerike, ktoré sú
        obklopené komunistickým vplyvom. A práve tejto znepokojujúcej skutočnosti dnes ľudstvo čelí: zlý duch vo svojom
        sprisahaní s cieľom zničiť ľudstvo takmer uspel.</p>
      <p>Ľudia vnútorne túžia po tom, aby sa im vodilo lepšie a unikli nebezpečenstvu. Inštinkt im hovorí, aby sa
        vyhýbali utrpeniu, aby sa preslávili, aby zakladali prosperujúce podniky, alebo aby si užívali život. Mať takéto
        myšlienky je ľudské. Ak sa však ľudia vzdialia Nebesiam, ich myšlienok sa môže zmocniť zlý duch, ktorý tieto
        myšlienky zosilní natoľko, že bude schopný človeka ovládať.</p>
      <p>Arogantná vzbura tohto ducha proti Nebesám dodáva tým, ktorých ovláda, pocit namyslenosti. Títo jednotlivci sa
        potom pomocou moci, peňazí alebo vedomostí sami hrajú na Boha, a týmito prostriedkami sa potom snažia ovládnuť
        osudy miliónov ľudí a cez spoločenské hnutia ovplyvniť smerovanie histórie.</p>
      <p>Ľudia boli stvorení Nebesami a vo svojej povahe majú dobro aj zlo. Ak ľudia opustia zlo a zvolia si súcit, môžu
        sa vrátiť do Nebies. Na opačnej strane čaká diabol. Voľbu robí každý človek sám.</p>
      <p>Mnohí v zásade láskaví ľudia sa nevedome stali prostredníkmi či terčmi manipulácie komunistického ducha – teda
        tým, koho Vladimír Lenin označil ako „užitoční idioti”.&nbsp; Hoci sa spoločnosť ako celok nachádza na pokraji
        zničenia práve kvôli podnetom a zvádzaniu tohto ducha, v skutočnosti len veľmi, veľmi málo ľudí vedome zasvätilo
        svoju dušu diablovi a dalo si za cieľ vedome škodiť ľudstvu. Väčšina ľudí si naďalej uchováva svoju vrodenú
        láskavosť, čo im dáva príležitosť zbaviť sa vplyvu tohto ducha komunizmu.</p>
      <p>Cieľom tejto knihy je jednoznačne a čo možno najpravdivejšie vysvetliť túto komplexnú a spletitú záležitosť.
        Ľudia potom budú schopní prehliadnuť triky komunistického ducha. Ešte dôležitejšie je, že táto kniha sa snaží
        prezentovať morálne, kultúrne a umelecké tradície, ktoré Nebesá odovzdali ľudstvu. Každý človek si tak bude sám
        môcť vybrať medzi Nebesami a zlým duchom.</p>
      <p>Keď sa v človeku prejavia jeho láskavé myšlienky, Nebesá mu pomôžu, aby sa vymanil z diablovej kontroly. Ale
        proces uvedomovania si toho, čo je diabol naozaj zač, vyžaduje, aby človek do hĺbky uvažoval a jasne rozlišoval.
        Táto kniha sa snaží znovu preskúmať obdobia histórie v priebehu posledných niekoľkých storočí a z vyššej úrovne
        a širšej perspektívy vyhodnotiť rozličné masky a formy, ktoré na seba diabol prevzal, aby okupoval náš svet
        a&nbsp;manipuloval ním.</p>
      <p>Cieľom tejto snahy nie je jednoducho zrekapitulovať históriu, ale pochopiť, ako môžeme zabrániť tomu, aby
        diabol v budúcnosti znovu náš svet ovládol. To si vyžaduje vlastné osvietenie každého jednotlivca, aktívne
        opustenie zla a návrat k tradíciám a&nbsp;k spôsobu života, ktorý Nebesá človeku poskytli.</p>
      <p>Nebesá zvíťazia nad diablom. To, na ktorú stranu sa postavíme, určí našu večnú budúcnosť.</p>
      <Link to={`/uvod`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">ďalšia kapitola</span>
      </Link>
      <Link to={`/`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">späť na hlavnú stránku</span>
      </Link>
    </ContentPageWrapper>
  );
};


